@import 'src/styles/imports';

.inputGroup {
  @include gappedColumn(0.5rem);
}

.dateAndTime {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  align-items: start;
}
