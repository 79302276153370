@import 'src/styles/imports';

.notificationApp {
  .appSwitch {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .userGroups {
    @include gappedColumn(1rem);
    padding: 2rem 0 1rem;

    .tags {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
    }
  }

  button {
    width: fit-content;
  }
}
