.form {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: var(--color-text-2nd);
  }
}

.userInputGroup {
  display: flex;
  gap: 4rem;
  padding: 0 1rem;
}

.previewContainer {
  display: flex;
  justify-content: center;
  padding: 2rem;
}

.footer {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  padding: 20px;
  flex-direction: column;
  border-top: 1px solid var(--color-border);

  .buttons {
    display: flex;
    justify-content: space-between;

    > div:only-child {
      margin-left: auto;
    }
  }

  .invalid {
    color: var(--color-text-2nd);

    > span {
      color: var(--color-attention);
    }
  }
}

.keywords {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;

  > div {
    background-color: var(--color-canvas);
    box-shadow: none;
  }

  .words {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 10px;
    padding: 10px 20px 20px 20px;

    span {
      border-radius: 99px;
      // display: flex;
      // align-items: center;
      // gap: 4px;
      padding: 4px 20px;
      color: var(--color-text);
      background-color: var(--color-border);
    }
  }
}

.customAccordion:global(.customAccordion) {
  button:global(.subHeader) {
    padding: 10px 0;
  }

  :global(.accordionItems) {
    margin: 0;
  }
}
