@import 'src/styles/imports';

.container {
  @include appContainer;

  h1 {
    margin-bottom: 15px;
  }
}

.appList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 40px;
}
