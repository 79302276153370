.card {
  border: 1px solid var(--card-border-color);
  width: 100%;
  max-width: 270px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.description {
  color: var(--card-text-secondary-color);
}

.skeleton {
  background-color: var(--canvas-bg-color);
  border-radius: 4px;

  &.icon {
    height: 2.4rem;
    width: 2.4rem;
  }

  &.name {
    height: 2.4rem;
    width: 100%;
  }

  &.desc {
    height: 9.6rem;
    width: 100%;
  }
}
