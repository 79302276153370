.wrapper {
  display: flex;
  height: 100%;

  > * {
    transition: all 0.5s ease-in-out;
    overflow-y: auto;
  }

  .slideOutContainer {
    //alt way to open/close => &:closed, transform: translate(100%, 0);
    height: 100%;
    overflow: hidden;
    padding-bottom: 2rem;
  }
}

.slideOutForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--card-bg-color);
  border: 1px solid var(--card-border-color);
  border-right: none;
  border-radius: 16px 0px 0px 16px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
  }

  .actions {
    display: flex;
    align-items: center;
  }

  .statusTag {
    margin-right: 1rem;
  }

  .content {
    height: 100%;
    padding: 2rem;
    overflow-y: auto;
  }

  .submitActionsContainer {
    border-top: 1px solid var(--card-border-color);
    display: flex;
    gap: 2rem;
    padding: 2rem;
    flex-direction: column;

    .submitActions {
      display: flex;
      gap: 1rem;
      height: min-content;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
    }

    .nonPrimaryActions {
      display: flex;
      flex: 1;
      justify-content: space-between;
    }
  }
}
