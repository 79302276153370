@import 'src/styles/imports';

.tileButton {
  padding: var(--card-padding);
  background-color: var(--card-bg-color);
  border: 1px solid var(--card-border-color);
  border-radius: var(--card-border-radius);
  display: flex;
  flex-direction: column;
  @include gappedColumn(1rem);
  text-align: start;

  &:hover {
    cursor: pointer;
  }

  .heading {
    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-direction: row;

    > h3 {
      // icon height
      line-height: 24px;
    }
  }

  > p {
    color: var(--card-text-secondary-color);
  }
}
