@import 'src/styles/imports';

.updateForm {
  @include gappedColumn(3rem);
}

.loadingIndicatorWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.tags {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.inputGroup {
  @include gappedColumn(0.5rem);

  .links {
    @include gappedColumn(1rem);
  }

  > button {
    width: fit-content;
  }

  .dateAndTime {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: start;
  }
}

.customAccordion:global(.accordion) {
  ul {
    border: 0;
  }

  button:global(.subHeader) {
    padding-inline: 0;
  }

  :global(.childrenContent) {
    padding-inline: 0;
  }

  :global(.accordionItem) {
    border: 0;
  }
}
