@import 'src/styles/imports';

.userCard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--card-border-color);
  background-color: var(--card-bg-color);
  padding: 1.5rem 2rem;
  border-radius: var(--card-border-radius);
  align-items: center;
}
