@import 'src/styles/imports';

.actionsContainer {
  padding: 2rem;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-top: 1px solid var(--card-border-color);
}

.actionsButtons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.error {
  padding-top: 1rem;
}

.removeSchedule {
  color: var(--color-intent-attention);
}
