@import 'src/styles/imports';

.expandableCard {
  width: 100%;
  background-color: var(--card-bg-color);
  border: 1px solid var(--card-border-color);
  border-bottom: 2px solid var(--card-border-color);
  padding: 0 2rem;
  border-radius: 1.6rem;

  .header {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 1rem;

    .headingGroupButton {
      // remove default button styling
      border: none;
      font: inherit;
      background: none;
      text-align: inherit;

      // actual styling
      display: flex;
      align-items: center;
      gap: 2rem;
      justify-content: space-between;
      flex-grow: 1;
      padding: 2rem 0;

      .headingText {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        > label {
          color: var(--card-text-secondary-color);
          word-break: break-all;

          &:hover {
            cursor: pointer;
          }
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .supplementaryInfo {
    display: flex;
    gap: 2rem;
  }

  .description {
    padding-bottom: 2rem;

    &.hasCheckbox {
      padding-left: 5.2rem;
    }
  }

  &.selected {
    outline: 2px solid $color-blue-700;
  }
}
