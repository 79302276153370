@import 'src/styles/imports';

// screen

.loadingScreen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.message {
  margin-top: 40px;
}
