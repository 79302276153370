@import 'src/styles/imports';

.notificationForm {
  @include gappedColumn(3rem);
}

.inputGroup {
  @include gappedColumn(0.5rem);

  .links {
    @include gappedColumn(1rem);
  }

  > button {
    width: fit-content;
  }
}
