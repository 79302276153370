@import 'src/styles/imports';

.container {
  @include appContainer;
}

.allLinks {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;

  .header {
    display: flex;
    justify-content: space-between;
  }
}

.linksList {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  padding-bottom: 2rem;

  svg {
    display: block;
  }
}

.loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filtersWrapper {
  display: flex;
  gap: 0.8rem;
  margin-bottom: 3.2rem;
  overflow-x: clip;
}

.secondaryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.secondaryHeaderActions {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.collapsibleContent {
  @include gappedColumn(20px);

  .section {
    @include gappedColumn(8px);

    .label {
      color: var(--card-text-secondary-color);
    }
  }
}
