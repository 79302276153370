.historyTimeline {
  padding: 20px 10px;
  .events {
    border-left: solid 1px var(--card-border-color);
    display: flex;
    flex-direction: column;
    gap: 3rem;

    .event {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 0 20px;

      > h3 {
        margin-top: -10px;
        text-transform: capitalize;
        &::before {
          content: '';
          left: -23.5px;
          top: calc(50% - 3px);
          position: absolute;
          background-color: var(--card-text-color);
          height: 6px;
          width: 6px;
          border-radius: 100%;
        }
      }

      > p {
        color: var(--card-text-secondary-color);
      }
    }
  }
}
