// Override existing dropdown styles
div.customDropdown {
  width: auto;
  flex-shrink: 0;

  button {
    background: white;
    // TODO: Remove importants after DropdownWithValue
    // has reduced its nesting
    border-radius: 100px !important;
    padding-inline: 2rem !important;
  }

  [role='option'] {
    text-wrap: nowrap;
  }
}
