@import 'src/styles/imports';

.container {
  @include appContainer;

  .allNotifications {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .notificationList {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin-top: 2rem;
      padding: 0.2rem 0.2rem 2rem;
      overflow-y: scroll;
      @include customScrollbar;
    }
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding-bottom: 4rem;
    .headingGroup {
      @include gappedColumn(2rem);
    }
  }

  .tiles {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .tabs {
    width: 100%;
  }
}
