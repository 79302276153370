@import 'src/styles/imports';

.errorContainer {
  width: 100%;
  height: 100%;
  margin-bottom: 4rem;
  justify-content: center;
  align-items: center;
  background-color: var(--card-bg-color);
  border-radius: 2rem;
  border: 1px solid var(--card-border-color);
  padding: 2rem;
  @include gappedColumn(2rem);
}

.textContainer {
  align-items: center;
  text-align: center;
  @include gappedColumn(0.8rem);
}

.smallTextContainer {
  align-items: center;
  text-align: center;
  @include gappedColumn(0);
}
