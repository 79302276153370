@import 'src/styles/imports';

.updateCard {
  border: 1px solid var(--card-border-color);
}

.topSection {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 2rem;
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.contentWrapper {
  @include gappedColumn(1.6rem);
}

.textWrapper {
  @include gappedColumn(0.8rem);
}

.tags {
  display: flex;
  gap: 1rem;
}

.title {
  opacity: 0;
  transition: 0.3s;
}

.description {
  margin-bottom: 1.6rem;
  opacity: 0;
  transition: 0.3s;
}

.links {
  @include gappedColumn(0.4rem);
}
.link {
  transition: 0.3s;
}

.visible {
  opacity: 1;
}
