.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.modalAction {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  .modalButtons {
    display: flex;
    gap: 2rem;
  }
}
