@use 'sass:math';

@mixin card {
  background-color: $white;
  border-radius: 4px;
  border: 1px solid $grey-100;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
}

@mixin appContainer {
  height: 100%;
  padding: 4rem 4rem 0;

  > * {
    max-width: 1000px;
    margin: auto;
  }
}

@mixin gappedColumn($gap: 20px) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin customScrollbar {
  // TODO: move customScrollbar to react library

  $custom-scrollbar-width: 14px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: $custom-scrollbar-width;
  }
  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: math.div($custom-scrollbar-width, 2);
    background-color: #00000020;
  }
}

@mixin preventUserSelection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}
