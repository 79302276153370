@import 'src/styles/imports';

.container {
  @include appContainer;
}

.title {
  margin-bottom: 2rem;
}

.impersonationWrapper {
  margin-bottom: 6rem;
}

.searchContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 4rem;

  .header {
    h1 {
      margin-bottom: 20px;
    }
  }

  .searchBar {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .notFound {
    color: var(--color-intent-attention);
  }
}

.applications {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  .links {
    border: 1px solid var(--card-border-color);
    background-color: var(--card-bg-color);
    border-radius: var(--card-border-radius);
    padding: 1.5rem 2rem;

    .link {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .appName {
        display: flex;
        gap: 10px;
      }
    }
  }
}
