@import 'src/styles/imports';

.inlineErrorMessage {
  color: var(--color-intent-attention);
  display: flex;
  gap: 0.5rem;
  white-space: noWrap;
  align-items: center;
  overflow: hidden;
  text-wrap: wrap;

  > p {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
  > svg {
    flex-shrink: 0;
  }
}
