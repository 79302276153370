@import 'src/styles/imports';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-bottom: 4rem;
}

.searchWrapper {
  margin-bottom: 1.6rem;
}

.filtersWrapper {
  display: flex;
  gap: 0.8rem;
  margin-bottom: 3.2rem;
  overflow-x: clip;
}

.cardsWrapper {
  @include gappedColumn(1.6rem);
  padding-bottom: 4rem;
}

.links {
  margin-top: 0.5rem;
  @include gappedColumn(0.5rem);
}
