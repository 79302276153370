@import 'src/styles/imports';

.container {
  @include appContainer;

  .heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
    .headingText {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }
}

.themeBuilder {
  display: grid;
  grid: auto-flow / 1fr 1fr;
  gap: 20px;
  color: var(--global-text-color);
  background-color: var(--card-bg-color);
  border-radius: var(--card-border-radius);
  padding: 2rem;

  h2 {
    color: var(--global-text-color);
    margin-bottom: 20px;
  }

  h3 {
    color: var(--global-text-color);
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
}

// meta

.baseTheme {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;

  .select {
    display: flex;
    background-color: var(--card-bg-color);
    border-radius: var(--card-border-radius);
    padding: 2rem;
    gap: 2rem;

    > button {
      width: fit-content;
      white-space: noWrap;
    }
  }
}

// setter

.VariablesSetter {
  color: #333;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;

  .input {
    flex: 1 1 auto;
    text-align: right;
    background-color: transparent;
    color: #333;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #333;
    font-size: 1.6rem;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.variables {
  background-color: var(--card-bg-color);
  padding: 20px;
  border: 1px solid var(--card-border-color);
  border-radius: var(--card-border-radius);
}

// preview

.ThemePreview {
  padding: 20px;
  background-color: var(--canvas-bg-color);
  color: var(--canvas-text-color);
  border-radius: var(--card-border-radius);
}

// global

.global {
  p {
    font-family: var(--global-font-family);
    font-size: var(--global-font-size);
    line-height: var(--global-line-height);
    color: var(--global-text-color);
  }
}

// intent

.intent {
  background-color: var(--card-bg-color);
  margin: 10px;
  padding: 20px;
  color: var(--card-text-color);
  border-radius: var(--card-border-radius);
  border-width: 1px;
  border-style: solid;
  font-weight: 500;
}

// accent

.accent {
  background-color: var(--card-bg-color);
  margin: 5px;
  padding: 20px;
  color: var(--card-text-color);
  border-radius: var(--card-border-radius);
  display: flex;
  align-items: center;
  gap: 10px;

  .pill {
    width: 5px;
    height: 20px;
    border-radius: 100px;
  }
}

// card

// card inputs

.cardInputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

// canvas

.canvas {
  background-color: var(--canvas-bg-color);
  color: var(--canvas-text-color);
}

// canvas inputs

.canvasInputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cardCtas,
.canvasCtas {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}
