@import 'src/styles/imports';

$size: 30px;

.ColorPicker {
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
}

.colorContainer {
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    text-transform: uppercase;
  }
}

.color {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #dadada;
}

.modal {
  display: flex;
  gap: 5px;
  background-color: var(--card-bg-color);
  border-radius: 12px;
  padding: 10px;
  border: 1px solid var(--card-border-color);
  box-shadow: 2px 4px 8px rgba(#000, 0.1);
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 9;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: all 0.3s ease-in-out;

  &[active] {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }
}

.left,
.right {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.colorBlock {
  width: $size;
  height: $size;
  border-radius: 6px;
  cursor: pointer;
}

.defaultColors,
.colorHistory {
  display: flex;
  gap: 5px;
}

.colorPreview {
  width: 100%;
  height: $size;
  border-radius: 6px;
  text-align: center;
  line-height: $size;
  font-weight: 900;
  text-transform: uppercase;
  transition: all 0.5s ease-in-out;
}

.customColor {
  display: flex;

  input {
    font-size: 1.6rem;
    padding: 0 8px;
    min-width: 6ch;
    border: 1px solid var(--card-input-border-color);
    border-radius: 0 6px 6px 0;
    height: $size;
    line-height: $size;
    text-transform: uppercase;
    color: #333;
  }

  span {
    background-color: #eee;
    display: inline-block;
    vertical-align: middle;
    height: $size;
    font-size: 1.6rem;
    line-height: $size;
    padding: 0 8px;
    border-radius: 6px 0 0 6px;
    color: #aaa;
  }
}
